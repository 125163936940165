<template>
<div id="account">
  <div class="account-box">
    <div class="account-left">
      <router-link :to="{path:'/personal'}"><h3><i class="el-icon-arrow-left"></i><span>返回个人中心</span></h3></router-link>
      <p @click="toggle(1)" :class="{active:sub==1}"><span>收藏的职位</span><i class="el-icon-arrow-right"></i></p>
      <p @click="toggle(2)" :class="{active:sub==2}"><span>关注的公司</span><i class="el-icon-arrow-right"></i></p>
      <p @click="toggle(3)" :class="{active:sub==3}"><span>浏览足迹</span><i class="el-icon-arrow-right"></i></p>
      <p @click="toggle(4)" :class="{active:sub==4}"><span>谁看过我</span><i class="el-icon-arrow-right"></i></p>
      <p @click="toggle(5)" :class="{active:sub==5}"><span>微信号</span><i class="el-icon-arrow-right"></i></p>
      <p @click="toggle(6)" :class="{active:sub==6}"><span>投递记录</span><i class="el-icon-arrow-right"></i></p>
      <p @click="toggle(7)" :class="{active:sub==7}"><span>面试通知</span><i class="el-icon-arrow-right"></i></p>
      <p @click="toggle(8)" :class="{active:sub==8}"><span>屏蔽公司</span><i class="el-icon-arrow-right"></i></p>
    </div>
    <div class="account-right">
      <div class="collect"  v-show="sub==1">
         <div class="account-header">收藏的职位</div>
         <div class="position-list" v-if="jobList.length>0">
            <div class="position-item" @click="jumpPosition(item.positionInfoId)" v-for="(item,index) in jobList" :key="index">
              <div class="ps-left">
                <h4>
                  {{item.positionName}}
                  <em>
                    {{item.minimumWage}}<td v-show="item.maximumSalary">-{{item.maximumSalary}}</td>
                  </em>
                </h4>
                <p>
                  <label><img width="23px" height="21px" src="@/assets/imgs/edu-icon.png">{{item.minimumEducationName}}</label>
                  <label><img width="20px" height="20px" src="@/assets/imgs/time-icon.png">{{item.workExperienceName}}</label>
                </p>
              </div>
              <div class="ps-mid">
                <img :src="item.headSculpture" />
                <p>
                  <span>{{item.userName}} · {{item.position}}</span>
                  <label>{{item.enterpriseName}}</label>
                </p>
              </div>
           </div>
         </div>
         <div class="noData" v-else>
           <img src="@/assets/imgs/personal/icon-tip-empty.png">
           <span>暂无收藏</span>
         </div>
      </div>
      <!-- 关注 -->
      <div class="follow"  v-show="sub==2">
         <div class="account-header">关注的公司</div>
         <div class="follow-list" v-if="companyList.length>0">
           <div class="follow-item" v-for="(item,index) in companyList" :key="index" @click="jumpCompany(item)">
              <img :src="item.enterpriseLogo" >
              <p>
                <span>{{item.enterpriseName}}</span>
                <label>{{item.enterpriseNatureName}}<strong></strong>{{item.scaleName}}<strong></strong>{{item.companyIndustryName}}</label>
              </p>
              <i class="el-icon-arrow-right"></i>
           </div>
         </div>
         <div class="noData" v-else>
           <img src="@/assets/imgs/personal/icon-tip-empty.png">
           <span>暂无关注</span>
         </div>
      </div>
      <div class="collect" v-show="sub==3">
         <div class="account-header">浏览足迹</div>
         <div class="position-list">
            <div class="position-item" @click="jumpPosition(item.positionInfoId)" v-for="(item,index) in browsingList" :key="index">
              <div class="ps-left">
                <h4>
                  {{item.positionName}}
                  <em>
                    {{item.minimumWage}}<td v-show="item.maximumSalary">-{{item.maximumSalary}}</td>
                  </em>
                </h4>
                <p>
                  <label v-show="item.minimumEducationName"><img src="@/assets/imgs/edu-icon.png">{{item.minimumEducationName}}</label>
                  <label v-show="item.workExperienceName"><img src="@/assets/imgs/time-icon.png">{{item.workExperienceName}}</label>
                </p>
              </div>
              <div class="ps-mid">
                <img :src="item.headSculpture" />
                <p>
                  <span>{{item.userName}} · {{item.position}}</span>
                  <label>{{item.enterpriseName}}</label>
                </p>
              </div>
           </div>
         </div>
         <div class="pagination">
          <el-pagination
            @size-change="browsSize"
            @current-change="browsCurrentChange"
            :current-page="browsParams.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="browsParams.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="browsParams.total">
          </el-pagination>
        </div>
      </div>
      <div class="collect" v-show="sub==4">
         <div class="account-header">谁看过我</div>
         <div class="position-list" v-if="seenList.length>0">
            <div class="position-item" @click="jumpPosition(item.positionInfoId)" v-for="(item,index) in seenList" :key="index">
              <div class="ps-left">
                <h4>
                  {{item.positionName}}
                  <em v-show="item.minimumWage=='面议'">面议</em>
                  <em v-show="item.minimumWage!='面议'">
                    {{item.minimumWage/1000}}-{{item.maximumSalary/1000}}K/月
                  </em>
                </h4>
                <p>
                  <label><img src="@/assets/imgs/edu-icon.png">{{item.minimumEducationName}}</label>
                  <label><img src="@/assets/imgs/time-icon.png">{{item.workExperienceName}}</label>
                </p>
              </div>
              <div class="ps-mid">
                <img :src="item.headSculpture" />
                <p>
                  <span>{{item.userName}} · {{item.position}}</span>
                  <label>{{item.enterpriseName}}</label>
                </p>
              </div>
           </div>
           <div class="pagination">
            <el-pagination
              @size-change="seenSize"
              @current-change="seenCurrentChange"
              :current-page="seenParams.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="seenParams.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="seenParams.total">
            </el-pagination>
          </div>
         </div>
         <div class="noData" v-else>
           <img src="@/assets/imgs/personal/icon-tip-empty.png">
           <span>暂无数据</span>
        </div>
      </div>
      <div class="real" v-show="sub==5">
        <div class="account-header fixWx-header">微信号</div>
        <el-form ref="form" :model="contactsInfo" label-width="100px">
          <el-form-item label="微信号">
            <el-input v-model="contactsInfo.wechatNum" placeholder="请输入您的微信号"></el-input>
          </el-form-item>
          <div class="submit-btn"  @click="setWeChat">修改</div>
        </el-form>
      </div>
      <div class="collect" v-show="sub==6">
         <div class="account-header">投递记录</div>
         <div class="position-list" v-if="deliveryList.length>0">
            <div class="position-item" @click="jumpPosition(item.id)" v-for="(item,index) in deliveryList" :key="index">
              <div class="ps-left delivey-left">
                <h4>
                  {{item.positionName}}
                  <em v-show="item.minimumWage=='面议'">面议</em>
                  <em v-show="item.minimumWage!='面议'">
                    {{item.minimumWage/1000}}-{{item.maximumSalary/1000}}K/月
                  </em>
                  <strong>(投递时间：{{item.createTime}})</strong>
                </h4>
                <p>
                  <label><img style="width:15px" src="@/assets/imgs/location-icon.png">{{item.areaName}}</label>
                  <label><img src="@/assets/imgs/edu-icon.png">{{item.minimumEducationName}}</label>
                  <label><img src="@/assets/imgs/time-icon.png">{{item.workExperienceName}}</label>
                </p>
              </div>
              <div class="ps-mid">
                <img :src="item.headSculpture" />
                <p>
                  <span>{{item.publisherName}} · {{item.position}}</span>
                  <label>{{item.enterpriseName}}</label>
                </p>
              </div>
           </div>
            <div class="pagination">
              <el-pagination
                @size-change="deliverySize"
                @current-change="deliveryCurrentChange"
                :current-page="deliveryParams.page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="deliveryParams.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="deliveryParams.total">
              </el-pagination>
            </div>
         </div>
         <div class="noData" v-else>
            <img src="@/assets/imgs/personal/icon-tip-empty.png">
            <span>暂无投递记录</span>
          </div>
      </div>
      <div class="follow" v-show="sub==7">
        <div class="account-header">面试通知</div>
        <div class="follow-list" v-if="interviewList.length>0">
          <div class="follow-item" v-for="(item,index) in interviewList" :key="index" @click="openInterView(item)">
            <img :src="item.enterpriseLogo" >
            <p>
              <span>
                {{item.enterpriseName}}
              </span>
              <label>{{item.positionInfoName}}
               <strong></strong>
              </label>
               <label v-show="item.minimumWage=='面议'">面议</label>
               <label v-show="item.minimumWage!='面议'">
                {{item.minimumWage/1000}}-{{item.maximumSalary/1000}}K/月
              </label>
            </p>
            <i class="el-icon-arrow-right"></i>
            <h4>
                <em v-show="item.exchangeStatus==0" style="color:#999">未接受</em>
                <em v-show="item.exchangeStatus==1" style="color:#01a7a7">已接受</em>
                <em v-show="item.exchangeStatus==2" style="color:#999">拒绝</em>
                <em v-show="item.exchangeStatus==3" style="color:#999">取消</em>
                <em v-show="item.exchangeStatus==4" style="color:#999">超时取消</em>
                <em v-show="item.exchangeStatus==5" style="color:#01a7a7">完成</em>
                <span>面试时间：{{item.createTime}}</span>
            </h4>
            
          </div>
        </div>
        <div class="noData" v-else>
          <img src="@/assets/imgs/personal/icon-tip-empty.png">
          <span>暂无面试通知</span>
        </div>
      </div>
      <div class="shield"  v-show="sub==8">
         <div class="account-header">屏蔽公司</div>
         <div class="addShield" @click="openShield">添加屏蔽公司</div>
         <div class="shield-item" v-for="(item,index) in shieldList" :key="index">
            <span>{{item.enterpriseName}}</span>
            <label @click="delEnterprises(item.id)">解除屏蔽</label>
         </div>
      </div>
    </div>
  </div>
  <!-- 面试详细 -->
  <div class="interviewDetail">
     <el-dialog
      title="面试通知详情"
      :visible.sync="interviewDialog"
      width="500px"
      >
        <el-form  label-width="110px" class="demo-ruleForm">
          <el-form-item label="职位:" >
            <label>{{interviewInfo.positionInfoName}} · </label>
            <span v-show="interviewInfo.minimumWage=='面议'">面议</span>
            <span v-show="interviewInfo.minimumWage!='面议'">
              {{interviewInfo.minimumWage/1000}}-{{interviewInfo.maximumSalary/1000}}K/月
            </span>
          </el-form-item>
          <el-form-item label="面试状态:" >
            <label v-show="interviewInfo.exchangeStatus==0" style="color:#999">未接受</label>
            <label v-show="interviewInfo.exchangeStatus==1" style="color:#01a7a7">接受</label>
            <label v-show="interviewInfo.exchangeStatus==2" style="color:#999">拒绝</label>
            <label v-show="interviewInfo.exchangeStatus==3" style="color:#999">取消</label>
            <label v-show="interviewInfo.exchangeStatus==4" style="color:#999">超时取消</label>
            <label v-show="interviewInfo.exchangeStatus==5" style="color:#01a7a7">完成</label>
          </el-form-item>
          <el-form-item label="面试时间:" >
             {{ interviewInfo.interviewTime }}
          </el-form-item>
          <el-form-item label="面试地址:" >
             {{ interviewInfo.interviewLocation }}{{ interviewInfo.houseNumber }}
          </el-form-item>
          <el-form-item label="联系人:" >
             {{ interviewInfo.contactsName }}
          </el-form-item>
          <el-form-item label="手机号:" >
             {{ interviewInfo.contactsPhone }}
          </el-form-item>
          <el-form-item label="备注:" >
             {{ interviewInfo.remark }}
          </el-form-item>
        </el-form>
     </el-dialog>
  </div>
  <!-- 添加屏蔽公司 -->
  <div class="addShield-box">
     <el-dialog
      title="添加屏蔽公司"
      :visible.sync="shieldDialog"
      width="500px"
      >
        <el-form  label-width="110px">
          <el-form-item label="公司名称" >
             <el-select v-model="enterpriseId" clearable filterable placeholder="请选择公司名称">
              <el-option
                v-for="item in enterpriseList"
                :key="item.id"
                :label="item.enterpriseName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="shieldDialog = false">取 消</el-button>
          <el-button type="primary" @click="saveShield">确 定</el-button>
        </span>
     </el-dialog>
  </div>
</div>
</template>

<script>
import {getEnterprisesList,delEnterprises,addEnterprises,getEnterpriseListByName,getPersonalInterviewInfoList,getBrowsingWhoHasSeenMyData,getInterviewInfoInfo, getPersonalDeliveryRecords,certification,getContactsInfo,setWeChat, getUserInfo,getPositionBrowsingInfo, verifyRealNameAuth,getCollectionPosition,getCollectionCompany } from "@/api/index.js";
export default {
  name: "account",
  data() {
    return {
      sub: 1,
      userModel: {
        userName: '',
        idCard: ''
      },
      userInfo:{},
      jobList:[],
      companyList:[],
      browsingList:[],
      browsParams:{
        page:1,
        size:10,
        total:0
      },
      deliveryParams:{
        page:1,
        size:10,
        total:0
      },
      deliveryList:[],
      contactsInfo:{},
      interviewList:[],
      interviewDialog:false,
      interviewInfo:{},
      seenParams:{
        page:1,
        size:10
      },
      seenList:[],
      shieldDialog:false,
      enterpriseList:[],
      enterpriseId:'',
      shieldList:[]
    }
  },
  mounted(){
     this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
     this.userModel.userName =  this.userInfo.authenticationName
     this.userModel.idCard =  this.userInfo.idCard
     if(this.$route.query.type){
       this.sub = this.$route.query.type
     }
     this.getCollectionPosition()
     this.getCollectionCompany()
     this.getContactsInfo()
     this.getPositionBrowsingInfo()
     this.getPersonalDeliveryRecords()
     this.getPersonalInterviewInfoList()
     this.getWhoHasSeenMyData()
     this.getEnterpriseListByName()
     this.getEnterprisesList()
  },
  methods: {
    delEnterprises(id){
      console.log(id)
       delEnterprises(id).then(res=>{
        if(res.code==200){
           this.$message({
            message: '解除屏蔽公司成功',
            type: 'success'
          })
          this.getEnterprisesList()
        }
      })
    },
    saveShield(){
      addEnterprises({
        enterpriseId:this.enterpriseId
      }).then(res=>{
        if(res.code==200){
           this.$message({
            message: '添加屏蔽公司成功',
            type: 'success'
          })
          this.getEnterprisesList()
          this.shieldDialog =false
        }
      })
    },
    getEnterprisesList(){
      getEnterprisesList({
        enterpriseId:'',
        page:1,
        size:100
      }).then(res=>{
        this.shieldList = res.rows
      })
    },
    openShield(){
      this.shieldDialog = true
    },
    getEnterpriseListByName(){
      getEnterpriseListByName({
        enterpriseName:''
      }).then(res=>{
        this.enterpriseList = res.rows
      })
    },
    getWhoHasSeenMyData(){
      getBrowsingWhoHasSeenMyData({
         page:this.seenParams.page,
         size:this.seenParams.size
      }).then(res=>{
        this.seenList = res.rows
        this.seenParams.total = res.total
      })
    },
    seenSize(val){
      this.seenParams.size= val
      this.getWhoHasSeenMyData()
    },
    seenCurrentChange(val){
      this.seenParams.page= val
      this.getWhoHasSeenMyData()
    },
    openInterView(item){
      this.interviewDialog = true
      getInterviewInfoInfo({
        id:item.id
      }).then(res=>{
        this.interviewInfo = res.data
      })
    },
    getPersonalInterviewInfoList(){
      getPersonalInterviewInfoList({
        page:1,
        size:100
      }).then(res=>{
        this.interviewList = res.rows
      })
    },
    getPersonalDeliveryRecords(){
      getPersonalDeliveryRecords({
        page:this.deliveryParams.page,
        size:this.deliveryParams.size
      }).then(res=>{
          this.deliveryList = res.rows
          this.deliveryParams.total = res.total
      })
    },
    deliverySize(value){
      this.deliveryParams.size= value
      this.getPersonalDeliveryRecords()
    },
    deliveryCurrentChange(value){
      this.deliveryParams.page= value
      this.getPersonalDeliveryRecords()
    },
    setWeChat(){
      setWeChat({
        wechatNum:this.contactsInfo.wechatNum
      }).then(res=>{
        if(res.code==200){
           this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.getContactsInfo()
        }
      })
    },
    getContactsInfo(){
      getContactsInfo().then(res=>{
        this.contactsInfo = res.data
      })
    },
    getPositionBrowsingInfo(){
      getPositionBrowsingInfo({
        page:this.browsParams.page,
        size:this.browsParams.size
      }).then(res=>{
         this.browsingList = res.rows
         this.browsParams.total = res.total
      })
    },
    browsSize(value){
      this.browsParams.size= value
      this.getPositionBrowsingInfo()
    },
    browsCurrentChange(value){
      this.browsParams.page= value
      this.getPositionBrowsingInfo()
    },
    jumpCompany(item){
      this.$router.push({
        path: '/companyDetail',
        query: {
          enterpriseId: item.enterpriseId
        }
      })
    },
    getCollectionCompany(){
      getCollectionCompany({
        page:1,
        size:100
      }).then(res=>{
        this.companyList = res.rows
      })
    },
    jumpPosition(positionId){
      this.$router.push({
        path: '/jobDetail',
        query: {
          positionId:positionId
        }
      })
    },
     getCollectionPosition() {
      getCollectionPosition({
        page:1,
        size:100
      }).then(res => {
        this.jobList = res.rows
        var benefitsList = JSON.parse(localStorage.getItem('benefitsList'))

        this.jobList.forEach(item => {
          if (item.materialBenefits) {
            var benefits = item.materialBenefits.split(',')
            item.benefitsMap = []
            benefits.forEach(g => {
              benefitsList.forEach(s => {
                if (g == s.dictValue) {
                  item.benefitsMap.push(s.dictLabel)
                }
              })
            })
          }
        })
      })
    },
    toggle(sub) {
      this.sub = sub
    },
    submit() {
      verifyRealNameAuth({
        idCard: this.userModel.idCard,
        userName: this.userModel.userName
      }).then(res => {
        if (res.bindingInfo) {
          this.$confirm('此身份证信息已被，请核实信息的准确性，如无误继续认证将接触旧账号的绑定，并将旧账号的认证驳回，请确认是否继续认证！?', '身份已被绑定！', {
          confirmButtonText: '继续认证',
          cancelButtonText: '修改信息',
          type: 'warning'
        }).then(() => {
          this.certification()
        })
        }else{
          this.certification()
        }
      })
    },
    certification() {
      certification({
        idCard: this.userModel.idCard,
        userName: this.userModel.userName
      }).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '实名认证成功！',
            type: 'success'
          })
          this.getUserInfo()
        }
      })
    },
    getUserInfo() {
      getUserInfo().then(res => {
        this.userInfo = res.data
        localStorage.setItem('userInfo', JSON.stringify(res.data))
      })
    },
  }
};
</script>

<style lang="scss" scoped>
#account {
  min-height: 600px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  overflow: hidden;
  padding-bottom: 20px;
}
.shield{
  .addShield{
    width: 150px;
    height: 38px;
    text-align: center;
    line-height: 38px;
    color: #fff;
    font-size: 15px;
    color: #FFFFFF;
    background: #0077FF;
    border-radius: 3px;
    margin: 20px;
    cursor: pointer;
  }
  .shield-item{
    height: 55px;
    line-height: 55px;
    margin: 0 20px;
    border-bottom: 1px solid #eee;
    label{
      display: block;
      float: right;
      color: #0077FF;
      cursor: pointer;
      font-size: 15px;
    }
  }
}
.account-box {
  width: 1200px;
  margin: 0 auto;
  padding-top: 24px;
}
.noData{
  text-align: center;
  img{
    width: 200px;
    height: 120px;
    margin-top: 120px;
  }
  span{
    display: block;
    margin-top: 8px;
    font-size: 13px;
    color: #999;
  }
}
.account-left {
  width: 405px;
  min-height: 200px;
  background: #FFFFFF;
  float: left;

  h3 {
    height: 54px;
    line-height: 54px;
    font-size: 18px;
    font-weight: 400;
    color: #666666;
    border-bottom: 1px solid #ddd;
    margin: 0 20px;

    span {
      display: inline-block;
      margin-left: 5px;
    }
  }

  p {
    height: 54px;
    line-height: 54px;
    font-size: 16px;
    padding-left: 20px;
    cursor: pointer;
    color: #222222;

    span {
      display: inline-block;
      width: 350px;
    }
  }

  .active {
    background: #0077FF;
    color: #fff;
  }
}

.account-right {
  float: left;
  width: 785px;
  min-height: 487px;
  background: #FFFFFF;
  margin-left: 10px;

  .account-header {
    height: 54px;
    line-height: 54px;
    border-bottom: 1px solid #ddd;
    margin: 0 20px;
    font-size: 18px;
    font-weight: 500;
    color: #222222;
  }

  .real {
    p {
      font-size: 16px;
      color: #999999;
      margin: 30px 20px 14px;
    }

    .submit-btn {
      width: 140px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      color: #fff;
      font-size: 15px;
      color: #FFFFFF;
      background: #0077FF;
      border-radius: 3px;
      margin-top: 25px;
      margin-left: 100px;
      cursor: pointer;
    }
  }
 .fixWx-header{
   margin-bottom: 30px;
 }
}
.position-list {
  .position-item {
    min-height: 70px;
    margin: 12px 20px 0;
    background: #FFFFFF;
    border-radius: 10px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
  }

  .ps-left {
    padding-bottom: 20px;
    float: left;
    min-width: 350px;

    h4 {
      font-size: 18px;
      font-weight: 500;
      color: #222222;
      
      strong{
        font-weight: 100;
        font-size: 13px;
      }
      span {
        display: inline-block;
        width: 41px;
        height: 21px;
        line-height: 21px;
        text-align: center;
        border: 1px solid #FF2400;
        border-radius: 2px;
        font-size: 14px;
        color: #FF2400;
        margin-left: 10px;
      }

      label {
        display: inline-block;
        width: 41px;
        height: 21px;
        border: 1px solid #F99746;
        border-radius: 2px;
        line-height: 21px;
        text-align: center;
        font-size: 14px;
        color: #F99746;
        margin-left: 9px;
        margin-right: 15px;
      }

      em {
        font-style: normal;
        font-size: 18px;
        color: #FF2400;
        margin-left: 20px;
      }
    }

    p {
      margin-top: 13px;

      img {
        position: relative;
        top:3px;
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }

      label {
        display: inline-block;
        font-size: 14px;
        color: #999999;
        margin-right: 45px;
      }
    }
  }
  .delivey-left{
    min-width: 420px;
    h4{
      strong{
        margin-left: 30px;
        color: #999;
      }
    }
  }
  .ps-mid {
    float: left;
    margin-right: 10px;

    img {
      width: 61px;
      height: 61px;
      float: left;
      border-radius: 6px;
    }

    span {
      display: block;
      font-size: 18px;
      color: #222222;
      margin-bottom: 6px;
      margin-top: 2px;
    }

    p {
      float: left;
      margin-left:15px;
      font-size: 14px;
      color: #999999;
      margin-top: 6px;
      margin-bottom: 8px;
    }
  }
}
.follow-item{
  min-height: 76px;
  margin: 10px 25px 0;
  background: #FFFFFF;
  border-radius: 10px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  img{
    width: 61px;
    height: 61px;
    float: left;
    border-radius: 6px;
    margin-top: 3px;
  }
  p{
    float: left;
    margin-left: 20px;
    font-size: 14px;
    color: #999999;
    margin-top: 6px;
    margin-bottom: 10px;
    strong{
      display: inline-block;
      width: 1px;
      height: 12px;
      background: #D8DDE3;
      margin: 0 15px;
      position: relative;
      top: 2px;
    }
  }
  span{
    display: block;
    font-size: 17px;
    color: #222222;
    margin-bottom: 8px;
    margin-top: 3px;
  }
  label{
    font-size: 14px;
    color: #999999;
  }
  h4{
    float: right;
    font-weight: 400;
    margin-right: 30px;
    margin-top: 10px;
    em{
      font-style: normal;
    }
    span{
      font-size: 13px;
      color: #666;
      margin-top:7px;
    }
  }
  i{
    float: right;
    font-size: 25px;
    color: #999;
    margin-top: 20px;
  }
}
.pagination{
  margin: 10px;
}
</style><style lang="scss">
.account-right {
  .real{
     .el-input__inner {
      width: 300px;
      height: 40px;
      background: #F5F5F5;
      border-radius: 5px;
      border: none;
    }

    .el-form-item {
      margin-bottom: 10px;
    }

    .el-form-item__label {
      font-size: 16px;
      color: #222222;
    }
  }
}
.interviewDetail{
  .el-dialog__body{
    padding:5px 20px 20px;
  }
  .el-form-item{
    margin-bottom: 0;
  }
}
.addShield-box{
  .el-dialog__body{
    padding:20px 0;
  }
  .el-select{
    width: 340px;
  }
}
</style>
